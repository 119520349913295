import { IPatientDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import dayjs from 'dayjs';
import AudiogramDiagram from 'modules/hearing-test/audiogram-diagram';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';

const PatientOverviewLastTestWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();

  return (
    <SharedCard
      outerClassName='col-span-2 row-span-4 flex flex-col'
      title={t('patients.patient.patient_overview.last_test_widget.title')}
      innerClassName='p-4 relative flex flex-col min-h-[600px]'
    >
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.PATIENT_HEARING_TESTS_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const test = patient.lastHearingTest;

  return (
    <>
      {test?.testDate && (
        <p className='text-gray-400'>
          {t('hearing_test.add_edit_hearing_test.form.test_date')}: {dayjs(test.testDate.toDate()).format('DD/MM/YYYY')}
        </p>
      )}
      {test?.details ? (
        <div className='grow flex'>
          <div className='h-full' />
          <AudiogramDiagram
            onValueChange={() => {}}
            audiogramValues={test.details?.audiogram}
            height='100%'
            id={patient.uid}
            viewOnly
          />
        </div>
      ) : (
        <div className='absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col items-center text-center'>
          <p className='text-gray-300'>{t('patients.patient.patient_overview.last_test_widget.no_test')}</p>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.PATIENT_HEARING_TESTS_CREATE], [Permission.ORGANISATION_OWNER]]}
          >
            <Link to={`/hearing-test/create?patient=${patient.uid}`}>
              <SharedButton
                labelKey='patients.patient.patient_hearing_tests.create'
                appearance='link'
                primaryOverride
              />
            </Link>
          </SharedElementPermissionGuard>
        </div>
      )}
    </>
  );
};

export default PatientOverviewLastTestWidget;
