export enum CollectionID {
  USERS = 'users',
  ORGANISATIONS = 'organisations',
  GROUPS = 'groups',
  CLINICS = 'clinics',
  APPOINTMENT_TYPES = 'appointmentTypes',
  UPLOAD_TYPES = 'uploadTypes',
  AUDIT_LOG = 'auditLog',
  LEAD_TYPES = 'leadTypes',
  PATIENT_FORM_SETTINGS = 'patientFormSettings',
  PATIENTS = 'patients',
  ORDER_FORM_SETTINGS = 'orderFormSettings',
  HEARING_AIDS = 'hearingAids',
  ACCESSORIES = 'accessories',
  SERVICES = 'services',
  AUDIOMETERS = 'audiometers',
  PATIENT_WORKFLOWS = 'patientWorkflows',
  PATIENT_NOTES = 'patientNotes',
  PATIENT_DOCUMENTS = 'patientDocuments',
  PATIENT_ALERTS = 'patientAlerts',
  META = 'meta',
  APPOINTMENTS = 'appointments',
  HEARING_TESTS = 'hearingTests',
  STOCK = 'stock',
  COMMUNICATIONS = 'communications',
  ORDERS = 'orders',
  TRANSACTIONS = 'transactions',
  CUSTOM_FORMS_TEMPLATES = 'customFormTemplates',
  CUSTOM_FORMS = 'customForms',
  SCHEDULED_REMINDERS = 'scheduledReminders',
  HOLIDAYS_AND_UNAVAILABILITY = 'holidaysAndUnavailability',
}
